import {backendApi} from "../backend-api-sr"

export const kriMaster = (() =>{
    const test = (() => {
        return ""
    })

    const getOffice = ( async (subPath) => {
        var url = '/api/kri/master/getOfficeOne'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })    

    const getRegion = ( async (subPath) => {
        var url = '/api/kri/master/getRegionOne'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    return {getOffice, getRegion};

})()